<template>
  <div class="modal modal_custom" :class="{ 'is-active': active }">
    <div class="modal-background"></div>
    <div class="modal-card">
      <section class="modal-card-body">
        <div class="columns is-multiline is-centered">
          <div class="column is-12">
            <p class="modal-card-title has-text-centered">
              {{ modeEdit ? 'Editar' : 'Agregar' }} administrador
            </p>
          </div>
          <div class="column is-12">
            <div class="field">
              <p class="font_14 has_gray has-text-weight-semibold mb-2">
                Nombre completo:
              </p>
              <div class="control">
                <input
                  class="input px-3 py-3"
                  type="text"
                  name="name"
                  v-model="name"
                  v-validate="'required'"
                  data-vv-as="del nombre"
                >
                <span v-show="vverrors.has('name')" class="help is-danger">{{ vverrors.first('name') }}</span>
              </div>
            </div>
          </div>
          <div class="column is-12">
            <div class="field">
              <p class="font_14 has_gray has-text-weight-semibold mb-2">
                Correo electrónico:
              </p>
              <div class="control">
                <input
                  class="input px-3 py-3"
                  type="text"
                  name="email"
                  v-model="email"
                  :disabled="modeEdit"
                  v-validate="'email|required'"
                  data-vv-as="del correo electrónico"
                >
                <span v-show="vverrors.has('email')" class="help is-danger">{{ vverrors.first('email') }}</span>
              </div>
            </div>
          </div>
          <div class="column is-12" v-if="modeEdit">
            <div class="is-flex is-justify-content-flex-start is-align-items-center">
              <span class="font_15 has_gray has-text-weight-semibold mr-2">
                Estatus:
              </span>
              <span>
                <input
                  id="switchStatus"
                  type="checkbox"
                  class="switch is-rounded"
                  :checked="status ? 'checked' : ''"
                  v-model="status"
                  name="switchStatusStatus"
                >
                <label for="switchStatus"></label>
              </span>
            </div>
          </div>
        </div>
      </section>
      <footer class="modal-card-foot is-justify-content-space-around">
        <button
          class="button is_outlined"
          :disabled="loading"
          @click="$emit('close-modal')"
        >
          Cancelar
        </button>
        <button
          class="button btn_violet"
          :disabled="loading"
          :class="{ 'is-loading' : loading }"
          @click="setDataAction"
        >
          Guardar
        </button>
      </footer>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ModalAdministrator',
  props: {
    active: {
      type: Boolean,
      default: false
    },
    modeEdit: {
      type: Boolean,
      default: false
    },
    dataAdmin: {
      type: Object,
      default: () => {}
    },
    createUser: {
      type: Function,
      default: () => {}
    },
    editUserInfo: {
      type: Function,
      default: () => {}
    }
  },
  data () {
    return {
      loading: false,
      name: null,
      email: null,
      status: false
    }
  },
  methods: {
    async setDataAction () {
      if (!this.modeEdit) {
        const userInfo = {
          name: this.name,
          email: this.email
        }
        if (await this.$validator.validateAll()) {
          this.loading = true
          const resp = await this.createUser(userInfo)
          if (resp.success) {
            this.name = null
            this.email = null
          }
          this.loading = false
        }
      } else {
        if (await this.$validator.validateAll()) {
          this.loading = true
          const userInfo = {
            name: this.name,
            status: this.status
          }
          await this.editUserInfo(this.dataAdmin.id, userInfo)
          this.loading = false
        }
      }
    },
    spreadData () {
      this.name = this.dataAdmin.name || null
      this.email = this.dataAdmin.email || null
      this.status = this.dataAdmin.active || null
    }
  },
  beforeMount () {
    if (this.modeEdit) {
      this.spreadData()
    }
  }
}
</script>

<style lang="scss" scoped>
  .modal_custom {
    .button {
      width: 140px;
    }
  }
</style>
